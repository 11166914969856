import { render, staticRenderFns } from "./token-contract.vue?vue&type=template&id=391c6a61&scoped=true&"
import script from "./token-contract.vue?vue&type=script&lang=js&"
export * from "./token-contract.vue?vue&type=script&lang=js&"
import style0 from "./token-contract.vue?vue&type=style&index=0&id=391c6a61&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "391c6a61",
  null
  
)

export default component.exports